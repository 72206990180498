.multipleInputFiledContainer {
    display: flex;
    margin-top: 10px;
}

.singleInputFiledContainer {
    margin-top: 10px;
}

.aeroInputField {
    width: 100%;
}


.auto-suggest-input:focus, .aeroInputField:focus {
    outline: none !important;
    border: 1.1px solid #0C7BA1;
}

.auto-suggest-input, .aeroInputField {
    border: 1px solid #CED5D8;
    display: block;
    height: 40px;
    width: -webkit-fill-available;
    color: #01151D;
    background-color: #fff;
    background-image: none;
    -webkit-transition: border-color .3s ease-in-out;
    transition: border-color .3s ease-in-out;
    border-radius: 6px;
    padding: 0px 12px 0px 16px;
    font-size: 16px;
    line-height: 1.42857143;
}

.auto-suggest-container {
    margin: 0;
    padding:  0px 12px 0px 16px;
    color: #01151D;
    overflow-x: hidden;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none !important;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum";
    z-index: 1050;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 16px;
    background-color: #fff;
    border-radius: 4px;
    outline: none;
    position: absolute;
    max-height: 30vh;
    width: -webkit-fill-available;
    overflow-y: scroll;
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
}

.auto-suggest-suggestion {
    list-style: none;
    list-style-type: none;
    padding: 5px 0px;
    margin: 0px;
    line-height: 1.5;
    font-size: 16px;
    text-wrap: wrap;
    color: #01151D;
}

.requiredStar {
    color: #8C0F2E;
}

.frmLabel {
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.0025em;
    text-align: left;
    color: #01151D;
}

.secondaryInputField {
    margin-left: 15px;
    width: 100%;
}

.aeroInputField:hover {
    outline: none !important;
    border-color: #0C7BA1 !important;
}


textarea {
    resize: none !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    overflow: auto;
}

.rs-modal-header {
    padding-right: 0px !important;
}