.SecondarySection-main-div {
    height: 320px;
    background: #FFFFFF;
}

.resultsCount-div {
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #667579;
    padding: 10px 0px 10px 20px;
    border-bottom: 1px solid #E5EBED;
    background: #FFFFFF;
}

.emptyResults-div {
    margin-top: 21vh;
}

.noResultsFound-div {
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.005em;
    text-align: center;
    color: #667579;
}

.couldnotFind-div {
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.0025em;
    text-align: center;
    color: #B6C0C2;
}

.typeText-div {
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.0025em;
    text-align: center;
    color: #667579;
    padding: 0px 60px;
}

.searchResultsContainer {
    height: calc(100vh - 350px);
    overflow: scroll;
}