.echelonHomepageContainer {
    background-image: url(https://needsassessment.jll.com/static/media/column2_background.bc59a4f84df0f3eb831a.png);
    background-position-y: 100%;
    background-repeat: no-repeat;
    position: relative;
}
.echelonBlogDetailsContainer {
    padding: 40px 0 20px 20px;
    height: 100vh;
    margin: 0 auto;
    display: flex;
}