.userCircle-details {
    width: 40px;
    height: 40px;
    border-radius: 56px;
    border: 1.5px;
    display: flex;
    background-color: #FFFFFF;
    align-items: center;
    justify-content: center;
    color: #667579;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0025em;
}

.avatarMain-div {
    display: inline-flex;
    flex-direction: row;
}

.user-avatar {
    margin: auto 20px auto auto;
}

.header-background {
    background-color: #EDF3F4;
    padding: 72px 20px 20px 20px;
}

.username-div {
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.005em;
    text-align: left;
    margin-left: 10px;
}

.marketText-div {
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.0025em;
    text-align: left;
    margin-left: 10px;
}

.contactTable-row-div {
    width: 100%;
    min-height: 40px;
    border-bottom: 1px solid #E5EBED;
}

.contactTable-firstRow-div {
    width: 100%;
    border-bottom: 1px solid #E5EBED;
}

.contactTable-left-column {
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #667579;
    width: 30%;
}

.contactTable-right-column {
    font-family: Source Sans Pro;
    color: #01151C;
    width: 70%;
}

.verticalCenter-para {
    margin: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.hyperlinkColor {
    color: #0052CC;
    cursor: pointer;
}

.passage-div {
    margin: 20px 0px 0px 0px;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #667579;
}

.address-para {
    word-wrap: break-word;
    margin: 0px !important,
}

.email-div {
    padding-top: 5px;
    word-wrap: break-word;
}

.routerLink {
    text-decoration: none;
    text-align: left;
    color: #667579;
}

.textDecoration-none {
    text-decoration: none;
}

.font-16-400 {
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.0025em;

}

.textAlign-right {
    text-align: right;
}

.achortext-color {
    color: #01151C;
}

.table-div-contactDet {
    margin-top: 10px;
    padding: 20px;
    height: 635px;
}

.statusSection {
    display: flex;
    background: #EDF3F4;
    justify-content: space-around;
    border-radius: 10px;
    margin-top: 20px;
}

.statusSectionRadioPanel {
    padding: 14px 0;
    display: flex;
}

.radioSelection {
    margin-right: 10px;
}

.confirmToActivate {
    width: 218px;
    height: 100px;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.005em;
    text-align: left;
    color: #01151C;
}

#ContactDetailsModal .sol-dialog {
    width: 400px !important;
    height: auto;
    border-radius: 16px;
}

#ContactDetailsModal .confirmToActivate {
    width: auto;
    height: auto;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.005em;
    text-align: left;
    color: #01151C;
}

#ContactDetailsModal .buttonClass {
    width: auto;
    height: 44px;
    border-radius: 8px;
    gap: 8px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0025em;
    text-align: left;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #1E697614;
    color: #01151C;
}

#ContactDetailsModal .dialogHeader {
    width: 70%;
    padding: 12px 20px 0px 20px;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: bold;
}