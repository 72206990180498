.CompanyDetailsMobile {
    padding: 0px 20px 70px 20px;
    overflow: auto;
    height: calc(100vh - 325px);
}

.aboutCompanyTitle {
    font-size: 20px;
    color: #667579;
    padding: 20px 0px 10px 0px;
}

.companyDetail {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #667579;
    border-bottom: 1px solid #E5EBED;
    padding: 10px 0px;
}

.companyDetailLabel {
    padding-right: 20px;
    text-wrap: nowrap;
}

.companyDetailText {
    font-size: 16px;
    color: #01151C;
    text-decoration: none;
    text-align: end;
}