
.feedCardDetailsContainer {
    padding: 16px 0 20px 0;
    border-bottom: 1px solid #DFE5E8;
    font-size: 16px;
}

.feedCardCompanyName {
    color: #01151D !important;
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    font-family: 'Source Sans 3';
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.feedCardHeader {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
}

.feedCardDetails {
    display: flex;
    color: #637177;
    justify-content: space-between;
}

.feedCardPillsContainer {
    display: flex;
    height: 28px;
    padding: 0 12px;
    border-radius: 3px;
    font-size: 14px;
    margin-right: 10px;
    white-space: nowrap;
}

.feedCardLocation {
    margin: auto 0 auto 8px;
    color: #637177;
}

.feedCardBrokerInfo {
    margin-bottom: 12px;
    display: flex;
}

.feedCardSubject {
    margin-bottom: 8px;
    color: #637177;
}

.feedCardDescription {
    color: #637177;
}

.feedCardDate {
    white-space: nowrap;
}

.avatarImage, .avatarImageCount, .avatarImageFixed {
    height: 40px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    margin-right: -10px;
    width: 40px;
    background: #EAEFF1;
    color: #637177;
    display: inline-flex;
    align-items: center;
}

.avatarImage img, .avatarImageFixed img {
    width: 40px;
    height: auto;
}

.avatarImage:hover {
    margin-right: 0 !important;
    transition: margin .15s;
}

.smallAvatarImage {
    width: 30px !important;
    height: 30px !important;
}


.avatarContainer {
    display: flex;
    flex-wrap: wrap;
    margin-right: 10px;
}