.header-highlight-div {
    background-color: #EDF3F4;
    height: 40px;
}

.header-div-FeedContainerMobile {
    display: inline-flex;
    flex-direction: row;
    padding: 0px 20px;
}

.header-text {
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.0025em;
    text-align: left;
    margin-right: 25px;
}

.highlight-header-text {
    border-bottom: 1px solid #0C7BA1;
    color: #01151C;
}

.nonHighlight-header-text {
    color: #667579;
}

.DetailsContainerMobile-maindiv {
    max-height: 540px;
    height: 457px;
    }