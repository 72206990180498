.switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 20px;
}
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 14px;
    left: 2px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
}

input:checked + .slider {
    background-color: #0C7BA1;
}

input:focus + .slider {
    box-shadow: 0 0 1px #66B887;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.toggle-message {
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.005em;
    text-align: left;
    padding-left: 10px;
}
