
.toDoList {
  color: #637177;
  overflow-y: hidden;
  height: 50%;
}

.toDoListChevron {
  align-content: center;
  margin-left: auto;
  color: black;
}

.toDoListSecondItem {
  border-top: 1px lightgray solid;
  margin-top: 20px;
  padding-top: 20px;
}

.toDoListDate {
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1.5px solid lightgray;
}

.toDoListEmpty {
  text-align: center;
}

.todoListConatiner {
  display: flex;
}

.todoListDisplayName {
  display:block;
  max-width: 15vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #01151D;
}