.Result {
    display: flex;
    padding: 20px 0px;
    border-bottom: #E5EBED 1px solid;
}

.companyResultHeader {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.005em;
    color: #01151C;
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.companyResultText1 {
    color: #01151C;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0em;
    margin-top: 12px;
}

.companyResultText2 {
    color: #667579;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0025em;
}

.client {
    background: #ff1a5312;
    width: 40px;
    height: 32px;
    border-radius: 42px;
    display: flex;
    justify-content: center;
}

.substantialActivity {
    background: #D0ECF5;
    width: 40px;
    height: 32px;
    border-radius: 42px;
    display: flex;
    justify-content: center;
}

.recentOpportunity {
    background: #C5F6DC;
    width: 40px;
    height: 32px;
    border-radius: 42px;
    display: flex;
    justify-content: center;
}

.pills {
    display: flex;
    gap: 4px;
}

.trophy {
    width: 12px;
}

.contactResultText {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0025em;
    color: #01151C;
}

.contactResultCircle {
    min-width: 44px;
    height: 44px;
    border-radius: 56px;
    border: 1.5px;
    margin-right: 16px;
    display: flex;
    background-color: #1e697614;
    align-items: center;
    justify-content: center;
    color: #667579;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0025em;
}

.resultContainer {
    width: 100%;
}