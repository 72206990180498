.exitModal .sol-dialog--centered {
    bottom: 0;
    left: unset;
    top: unset;
    transform: none;
    width: 100vw !important;
    border-radius: 30px 30px 0px 0px;
}

.exitModal .sol-dialog__content>.sol-dialog__close-btn {
    display: none;
}

.exitModal .sol-dialog__backdrop {
    z-index: 1301;
    pointer-events: none;
}