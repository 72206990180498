.activeSelections {
    display: flex;
    background-color: #EDF3F4;
    color: #667579;
    justify-content: space-between;
    padding: 0px 65% 0px 20px;
}

.activeSelection {
    padding-bottom: 20px;
}

.companyContact {
    padding: 20px;
    display: flex;
    gap: 10px;
    border-bottom: #E5EBED 1px solid;
}

.companyContactCircle {
    width: 44px;
    height: 44px;
    border-radius: 56px;
    border: 1.5px;
    display: flex;
    background-color: rgba(30, 105, 118, 0.08);
    align-items: center;
    justify-content: center;
    color: #667579;
    font-size: 20px;
    line-height: 32px;
    text-transform: uppercase;
    letter-spacing: -0.005em;
}

.companyContactName {
    color: #01151C;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0025em;
}

.companyContactTitle {
    color: #667579;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0025em;
}

.companyContactRole {
    color: #01151C;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.0025em;
}

.contactsListContainer {
    padding-bottom: 30px;
    height: calc(100vh - 300px);
    overflow: scroll;
}