.globalHeaderContainer {
    height: 88px;
}
.globalHeaderSubContainer {
    height: 64px;
}

.header-container {
    background: #091E25;
    padding: 20px 16px 20px 24px;
    display: flex;
    height: 45px;
    justify-content: space-between;
}
.myrecords-container {
    margin-right: 12px;
}

.create-container {
    margin-right: 8px;
}
.logo-container {
    margin: auto 0;
    cursor: pointer;
}

.avatar-container {
    display: flex;
    margin-left: 10px;
}

.rs-nav-subtle .rs-nav-item {
    width: 130px !important;
}

.subheader-container {
    box-shadow: var(--elevation-weak-sharp-axis, 0px) var(--elevation-weak-sharp-projection, 2px) var(--elevation-weak-sharp-blur, 3px) 0px var(--elevation-weak-sharp-color, rgba(75, 91, 97, 0.12));
    width: 100%;

}

.subheader-nav {
    height: 64px;
}

.rs-nav-justified>.rs-nav-item {
    flex: none !important;
}

.rs-nav-justified {
    justify-content: center;
}

.navText {
    margin-left: 5px;
}

.rs-nav-item.rs-nav-item-active {
    color: #09779E !important;
}
.rs-nav-subtle .rs-nav-item.rs-nav-item-active:before {
    background-color: #01151D !important;
}

.rs-nav-subtle .rs-nav-item:focus, .rs-nav-subtle .rs-nav-item:hover {
    color: #09779E !important;
}

.rs-nav-justified>.rs-nav-item {
    display: flex;
    width: auto !important;
    margin-right: 20px;
    justify-content: center;
}