#CompanyPageModal .sol-dialog {
    width: 278px !important;
    height: 248px;
    border-radius: 16px;
}

.wantToAdd_Text {
    width: 218px;
    height: 32px;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.005em;
    text-align: left;
    color: #01151C;
}

.margin-top-12 {
    margin-top: 12px;
}

.margin-top-20 {
    margin-top: 20px;
}

.buttonClass {
    width: 238px;
    height: 44px;
    border-radius: 8px;
    gap: 8px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0025em;
    text-align: left;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #1E697614;
    color: #01151C;
}

.add_circle {
    width: 56px;
    height: 56px;
    top: 63px;
    left: 431px;
    padding: 12px 0px 0px 0px;
    gap: 8px;
    border-radius: 40px 0px 0px 0px;
    opacity: 0px;
    color: #667579;
}

.add_circle-div {
    margin-top: 12px;
    text-align: center;
}

.display-inlineFlex-row {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
}

.width-100pc {
    width: 100%;
    box-sizing: border-box;
}

.padding-width-20px {
    padding: 0px 20px
}

.archivedPill {
    display: flex;
    align-items: center;
    gap: 10px;
}

.loaderSection {
    display: flex;
    justify-content: center;
    margin-top: 35vh;
}
/* .addButton {
    position:relative;
    height: 100%;
    width:100%;
}

.plusIcon {
    height: 56px;
    width: 56px;
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:5px;
    margin:auto;
  }
   */
.loader {
    width: auto;
    height: 150px;
    display: block;
    position: relative;
    background: #EDF3F4;
    box-sizing: border-box;
}
.loader::after {
    content: '';
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    top: 15px;
    left: 15px;
    position: absolute;
    background-image: linear-gradient(100deg, transparent, rgba(255, 255, 255, 0.5) 50%, transparent 80%), linear-gradient(#DDD 56px, transparent 0), linear-gradient(#DDD 24px, transparent 0), linear-gradient(#DDD 18px, transparent 0), linear-gradient(#DDD 66px, transparent 0);
    background-repeat: no-repeat;
    background-size: 75px 130px, 55px 56px, 160px 30px, 260px 20px, 290px 56px;
    background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
    box-sizing: border-box;
    animation: animloader 1s linear infinite;
}

@keyframes animloader {
    0% {
        background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
    }
    100% {
        background-position: 150% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
    }
}
