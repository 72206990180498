
.leaseExpirations {
  color: #637177;
  overflow-y: hidden;
  height: 50%;
}

.leaseExpirationsDate {
  padding-left: 10px;
  margin-left: 10px;
}

.leaseExpirationsEmpty {
  text-align: center;
}

.leaseExpirationsTopText {
  display: flex;
  justify-content: space-between;
}

.leaseExpirationCompanyName {
  color: #01151D;
  font-weight: 500;
  width: 64%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}

.leaseExpirationCompanyName:hover {
  color: #01151D;
}