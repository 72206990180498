.companyFooter-main-div {
    height: 60px;
    background-color: #FFFFFF;
    border-top: 1px solid #E5EBED;
    padding: 0px 20px;
    position: fixed;
    bottom: 0;
}

.footeritems-div {
    height: 44px;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0px;
}

.img-text-div {
    width: 87.5px;
    height: 44px;
    display: inline-flex;
    flex-direction: column;
    text-align: center;
}

.footerText-div {
    color: #01151C;
    font-family: Source Sans Pro;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    height: 16px;
}

.addbtn-div {
    text-align: center;
    position: absolute;
    z-index: 10;
    bottom: 13px;
    margin-left: 45%;
}

.addIcon-img{
    bottom: 55px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}