.leaseExpirationDatepickerContainer {
  width: fit-content;
  margin-bottom: 22px;
}

.leaseExpirationDatepicker {
  display: flex;
  gap: 10px;
  height: 48px;
}

.leaseExpirationDatepickerLabel {
  color: #637177;
  font-size: 16px;
}

.leaseExpirationDatepickerColor {
  color: #637177;
}