
.feedCardContainer {
    padding: 24px 20px 12px 20px;
}

.feedHeaderIcon {
    margin: auto 12px auto 0px;
}

.feedHeaderContainer {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.feedCardHeader {
    font-size: 20px;
    color: #01151C;
    height: 32px;
    display: inline;
}

.feedCardSubHeader {
    line-height: 24px;
    font-size: 16px;
    color: #01151C;
}

.detailsContainer {
    color: #667579;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    border-bottom: 1px solid #E5EBED;
}

.detailsContainer:last-child {
    border-bottom: none !important;
}

.detailsLabel {
    font-size: 14px;
    margin: auto 0px;
}

.detailsValue {
    font-size: 16px;
    display: flex;
}

.headerPrivateIcon {
    margin: 6px 0px 6px 6px;
    color: #667579;
}

.feedDescriptionContainer {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding-top: 20px;
    color: #667579;
}

.feedDivider {
    border-top: 1px solid #E5EBED;
}
.detailsValueText {
    margin: auto 0px auto 7px;
}

.headerDescriptionContainer {
    color: #667579;
    font-size: 14px;
    margin-top: 12px;
}

.editIconContainer {
    text-align: right;
    color: #B6C0C2;
}

.hideFeedDescription {
    justify-content: space-between;
    width: 119px;
    padding-top: 5px;
    display: flex;
    font-size: 14px;
    color: #01151C;
}