.toolTipFooter {
    color: #FFFFFF !important;
    margin-bottom: 10px;
}


.toolTipContainer {
    font-size: 14px;
    text-align: center;
    color: #B7C0C3
}