.MainHeader {
    padding: 12px 20px 20px 20px;
    display: inline-flex;
    justify-content: space-between;
    flex-direction: row;
    background-color: #EDF3F4;
    height: 40px;
    position: fixed;
    z-index: 1;
}


.MainHeaderSolDropdown {
    width: 100px;
}
