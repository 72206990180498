.exportIcon {
  color: white;
}

.echelonHeader {
    padding-top: 11px;
    display: inline-flex;
    justify-content: space-between;
    flex-direction: row;
    height: 40px;
    position: fixed;
    width: 100%;
    z-index: 1;
    background: #000;
}

.navItemsContainer {
    display: flex;
    color: #FFFFFF;
    width: 25vw;
    margin-top: 5px;
}

.headerLogo {
    height: 18px;
    padding: 8px;
}

.headerUserCircle {
    width: 30px;
    height: 30px;
    border-radius: 56px;
    border: 1.5px;
    display: flex;
    background-color: #FFFFFF;
    align-items: center;
    justify-content: center;
    color: #667579;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0025em;
}
.navItem {
    margin-right: 25px;
    cursor: pointer;
    text-transform: uppercase;
}

.echelonUserSection {
    display: flex;
    z-index: 1000;
}