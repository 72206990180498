.eventFeedCardContainer {
    /*height: calc(100vh - 150px);*/
}

.eventFeedBody {
    height: calc(100vh - 290px) !important;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 16px;
}

.emptyEventFeedContainer {
    margin: 0 auto;
    text-align: center;
    padding: 5vw;
}

.rs-avatar>.rs-avatar-image {
    height: auto;
}