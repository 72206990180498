.blogListContainerDesktop {
  margin-top: 20px;
  width: 80vw;
}

.addNewBtn {
  width: 100px;
  display: flex;
  position: sticky;
  cursor: pointer;
  justify-content: center;
  margin: auto;
}

.addNewBtnPrimary {
  position: sticky;
}

.blogCardHeader {
  display: flex;
  padding-bottom: 10px;
  flex-wrap: wrap;
  gap: 5px;
}

.blogCardMainContainer {
  display: flex;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  font-family: "Source Sans Pro", serif !important;
  font-size: 14px !important;
}

.timPill {
  background: #D0ECF5;
  width: 75px;
  height: 20px;
  border-radius: 5px;
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #0C7BA1;
}

.jllOnlyPill {
  background: #ff1a5312;
  width: 75px;
  height: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  gap: 8px;
  color: #840E2C;
}

.blogCardBody {
  * {
    font-family: "Source Sans Pro", serif !important;
    font-size: 14px !important;

  }
  padding-bottom: 10px;
  line-height: 1.2;
  cursor: text;
}

.blogCardBody a[href*="echelon.jll.com"] {
  text-decoration: none;
  color: inherit;
  pointer-events: none;
}

.blogCardFooterText {}

.scrollContainer {
  height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 12px 0px 0px 30px;
}



.subMarketPill {
  background: lightgray;
  width: auto;
  height: 20px;
  font-size: 14px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
  padding: 0 10px;
}

/*.subMarketPill:hover {*/
/*    text-decoration: underline;*/
/*}*/

/*.subMarketPill:first-child {*/
/*    padding-left: 0px;*/
/*}*/

.blogCardFooter {
  display: flex;
  justify-content: space-between;
}

.blogEditIcon {
  width: 2%;
  margin-top: auto;
}

.blogPillsContainer {
  display: flex;
  gap: 5px;
}