.filterContainer {
    width: 20vw;
}


.select__indicator-separator {
    background-color: #FFFFFF !important;
}

.select__control:hover {
    border-color: hsl(0, 0%, 80%) !important;
    box-shadow: none !important;
}

.select__control {
    border-color: hsl(0, 0%, 80%) !important;
    box-shadow: none !important;
}

.selectedPill, .timPillSelected {
    height: 25px;
    margin-right: 5px;
    font-size: 14px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 60px;
    cursor: pointer;
    margin-bottom: 10px;
    padding: 0 10px;
    color: #840E2C;
    background: #ff1a5321
}

.timPillSelected {
    background: #D0ECF5 !important;
    color: #0C7BA1 !important;
}

.unselectedPill {
    background: #FFFFFF;
    height: 25px;

    font-size: 14px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 60px;
    border: 1px solid #667579;
    color: #667579;
    padding: 0 10px;
    cursor: pointer;
}

.select__value-container {
    height: 35px;
    overflow-y: scroll !important;
    font-size: 14px !important;
}


.searchInputContainer {
    border-radius: 3px;
    border: 1px solid #DDDDDD;
    box-shadow: 0 3px 12px 0 rgb(0 0 0/0.1),0 1px 2px 0 rgb(0 0 0/0.08)
}

.searchInputContainerNoOutline {
    input, .select__control {
       border: none !important;
    }
}

.searchInputContainer input, .searchInputContainerNoOutline input {
    border: none;
    border-radius: 3px;
    padding: 10px;
    outline: none;
    font-size: 14px !important;
}


.searchLabel {
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -0.0025em;
    text-align: left;
    color: #667579;
    padding-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.auto-suggest-input, .auto-suggest-input:focus {
    outline: none;
}

.auto-suggest-owner-input-padding {
    border: 1px solid #e5e5ea;
    display: block;
    background-image: none;
    -webkit-transition: border-color .3s ease-in-out;
    transition: border-color .3s ease-in-out;
    border-radius: 3px;
    outline: none !important;
    height: 33px;
    padding-left: 16px;
    font-size: 16px;
    line-height: 1.42857143;
}


.react-datepicker__input-container input {
    outline: none;
    border: 1px solid #ced5d8;
    width: -webkit-fill-available;
    height: 20px;
    font-size: 16px;
    padding: 5px 25px 7px 16px;
}

.echelon-auto-suggest-search-container {
    margin: 0;
    padding: 0;
    position: absolute;
    width: 20vw;
    color: rgba(0,0,0,.65);
    overflow-x: hidden;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none !important;
    font-feature-settings: "tnum";
    z-index: 1050;
    box-sizing: border-box;
    font-size: 16px;
    background-color: #fff;
    border-radius: 3px;
    outline: none;
    max-height: 30vh;
    overflow-y: scroll;
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
}

.select__control {
    background-color: transparent !important;
}

.auto-suggest-owner-search-input {
    border: 1px solid #ced5d8;
    display: block;
    height: 20px;
    color: #575757;
    background-color: #fff;
    background-image: none;
    transition: border-color .3s ease-in-out;
    border-radius: 3px;
    padding: 8px 0 7px 16px;
    font-size: 16px;
    line-height: 1.42857143;
}

.react-datepicker-wrapper {
    display: block;
}

.searchSelect {
    min-width: 150px !important;
}

.pillsContainer {
    padding-top: 10px;
    display: flex;
    justify-content: center;
    gap: 8px;
}

input {
    border: 1px solid #ced5d8;
    border-radius: 3px;
    color: #66747a;
    padding-left: 16px;
    font-size: 16px;
    font-family: "Source Sans Pro";
    height: 30px;
}

.filterContainer .inputTextField {
    width: -webkit-fill-available;
    padding-right: 25px;
    color: #667579 !important;
    font-size: 16px !important;
}
input:focus-visible {
    outline: none;
}
.searchIcon {
    cursor: pointer;
    position: absolute;
    right: 3px;
    top: 20%;
    color: #aaa;
    height: 20px;
    width: 20px;
}

.divider {
    height: 40px;
}

.submarketSelect {
    width: 19.2vw !important;
    font-size: 16px;

}

.searchFieldSection {
    margin: 10px 0px;
}

.react-datepicker__close-icon {
    padding-right: 0px !important;
}

.react-datepicker__close-icon::after {
    cursor: pointer;
     background-color: transparent !important;
    color: #aaa;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    font-family: 'sol-icons';
    padding: 0px 2px 0px 0px;
    font-size: 20px;
    line-height: 1;
    text-align: center;
    vertical-align: middle;
    content: "\f08a";
}

.dropdown-heading {
  padding-right: 0px !important;
  padding-left: 16px !important;
}

.dropdown-heading-dropdown-arrow {
  height: 20px;
  width: 20px;
  margin-right: 3px;
}

.xIcon {
  float: right;
  margin: -28px 2px 0px 0px;
  color: #aaa;
  height: 20px;
  width: 20px;
}

.filterContainer .datePicker {
    font-size: 16px;
    color: #667579;
}

.auto-suggest-suggestion-echelon {
    list-style: none;
    list-style-type: none;
    padding: 5px 15px;
    margin: 0px;
    line-height: 1.5;
    font-size: 16px;
    text-wrap: wrap;
}