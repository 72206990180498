.HomePageComponent {
  background-color: white;
  padding: 20px 24px;
  border-radius: 10px;
}

.HomePageComponentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  margin-bottom: 16px;
}