.FeedItemCardList-div {
    overflow-y: scroll;
}

.mainDiv-FeedItemCardMobile {
    min-height: 325px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E5EBED;
}

.substantialActivity-text {
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.005em;
    text-align: left;
}

.companyName-text {
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.0025em;
    text-align: left;
}

.header-FeedItemCardMobile {
    height: 75px;
    padding: 10px 20px;
}

.header-txt-div {
    width: 250px;
    display: inline-flex;
    flex-direction: column;
}

.edit-img {
    color: #B6C0C2;
    margin-top: 10px;
}

.vital_signs-img {
    margin-top: 12px;
}

.left-column {
    width: 50%;
    float: left;
    padding: 4px 0px;
}

.right-column {
    float: right;
    padding: 4px 0px;
}

.table-div {
    margin-top: 10px;
    padding: 0px 20px;
    height: 120px;
}

.table-row-div {
    width: 100%;
    height: 30px;
    border-bottom: 1px solid #E5EBED;
}

.handshake-img {
    color: #667579;
}

.about-div {
    max-height: 60px;
    padding: 0px 20px;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.about-div-fullDescription {
    padding: 0px 20px;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.fullDescriptionbtn-div {
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
}

.fDbtn-div {
    width: 120px;
    height: 36px;
    margin-top: 20px;
    padding: 0px 20px;
}

.keyboard_arrow_down-img {
    color: #01151C;
}

.recentOpportunityFeed {
    background: #C5F6DC;
    width: 20px;
    height: 20px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    padding: 8px;
}

.resultsMessage {
    margin-left: 20px;
    font-size: 14px !important;
    padding-bottom: 30px;
}