.sol-text-field {
    background-color: white !important;
    border: #CED6D8 1px solid;
    font-size: 16px !important;
    border-radius: 10px;
    display: inline-flex;
    overflow: hidden;
    width: -webkit-fill-available !important;
    min-width: fit-content;
    height: 44px !important;
    box-sizing: border-box;
    align-items: center;
    align-self: stretch;
    padding: 0px 16px;
    border-radius: 3px;
    box-shadow: transparent 0px 0px 0px 1px inset;
    outline: none;
    text-overflow: ellipsis;
    white-space: nowrap;
}

:global .sol-text-field__input-unfilled {
    background-color: white !important;
}

:global .sol-dropdown__input-filled {
    background-color: white !important;
}

.react-phone-input-2__flag {
    display: block;
  }

.inputClass {
    border: none !important;
}

.addressBox {
    background-color: #EDF3F4;
    padding: 20px;
    margin: 20px 0px 30px 0px;
    border-radius: 12px;
    font-size: 20px;
    color: #667579;
}

.ContactFooterButton {
    width: auto;
    height: 20px;
    margin-top: 20px;
    padding: 12px 16px 12px 16px;
    border-radius: 8px;
    gap: 8px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0025em;
    text-align: left;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #1E697614;
}

.react-autosuggest__input {
    border: 1px solid #CED6D8 !important;
    width: -webkit-fill-available;
    height: 40px;
    gap: 0px;
    border-radius: 3px 3px 3px 3px;
    border: 1px 0px 0px 0px;
    background: #FFFFFF;
  }

.auto-suggest-input:focus {
    outline: none !important;
    border: 1.1px solid #0C7BA1;
  }
.auto-suggest-input {
    border: 1px solid #e5e5ea;
    display: block;
    height: 29px;
    width: -webkit-fill-available;
    color: #575757;
    background-color: #fff;
    background-image: none;
    -webkit-transition: border-color .3s ease-in-out;
    transition: border-color .3s ease-in-out;
    border-radius: 6px;
    padding: 7px 30px 7px 16px;
    font-size: 16px;
    line-height: 1.42857143;
}
.auto-suggest-container {
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,.65);
    overflow-x: hidden;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none !important;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum";
    z-index: 1050;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 16px;
    background-color: #fff;
    border-radius: 4px;
    outline: none;
    position: absolute;
    max-height: 30vh;
    width: -webkit-fill-available;
    overflow-y: scroll;
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
}
.auto-suggest-suggestion {
    list-style: none;
    list-style-type: none;
    padding: 5px 0px;
    margin: 0px;
    line-height: 1.5;
    font-size: 16px;
    text-wrap: wrap;
}

.sol-dropdown__list-container {
    position: static !important;
}

.requiredStar {
    color: #b0133a;
}

:global .sol-dropdown__list-container {
    position: static !important;
}

:global .sol-dropdown {
    position: static !important;
}

.addressLineIcon {
    margin: -34px 5px 0px 0px;
    float: right;
}

:global .react-tel-input .form-control {
    width: -webkit-fill-available !important
}
