.error-main-div {
    height: 716px;
}

.hyperlinkColor {
    color: #0052CC;
    cursor: pointer;
}

.main-error {
    font-family: Source Sans Pro;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.0025em;
    color: #667579;

}

.main-error-div {
    height: 400px;
    padding-top: 200px;
    text-align: center;
}

.header-div {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40px;
    margin-top: 35px;
}

.header-main-div {
    height: 115px;
    background: #EDF3F4;
}

.jllLogo {
    width: 105px;
    height: 28px;
}