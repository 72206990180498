.CompanyDetailsHeader {
    background-color: #EDF3F4;
    padding: 80px 20px 20px 20px;
}

.recentOpportunityPill {
    background: #C5F6DC;
    width: 105px;
    border-radius: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #0C8348;
    padding: 6px;
}

.substantialActivityPill {
    background: #D0ECF5;
    width: 105px;
    height: 40px;
    border-radius: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #0C7BA1;
}

.clientPill {
    background: #ff1a5312;
    width: 105px;
    height: 40px;
    border-radius: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #840E2C;
}

.pills {
    gap: 4px;
}

.trophy {
    width: 18px;
}

.companyDetailsHeader {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.005em;
    padding-top: 10px;
    color: #01151C;
    display: flex;
    gap: 20px;
}

.companyDetailsText1 {
    color: #01151C;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0025em;
    margin-top: 12px;
    display: flex;
    gap: 4px;
}

.companyDetailsText2 {
    color: #667579;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0025em;
    display: flex;
    padding-left: 18px;
}

.CompanyDetails {
    padding: 12px 20px 20px 20px;
    background-color: #EDF3F4;
    width: 390px;
}