.fuzzySearchContainer {
  margin-left: 10%;
}

.auto-suggest-input,
.auto-suggest-input:focus {
  outline: none;
}


.auto-suggest-search-container {
  margin-top: 10px;
  padding: 0;
  position: absolute;
  width: 512px;
  color: rgba(0, 0, 0, .65);
  overflow-x: hidden;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none !important;
  font-feature-settings: "tnum";
  z-index: 1050;
  box-sizing: border-box;
  font-size: 16px;
  background-color: #fff;
  border-radius: 6px;
  outline: none;
  max-height: 570px;
  overflow-y: auto;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
}

.select__control {
  background-color: transparent !important;
}

.auto-suggest-fuzzy-search-input {
  width: 470px;
  height: 41px;
  border-radius: 8px;
  color: #FFFFFF;
  background-color: #0C7BA140;
  border: none;
}

.auto-suggest-fuzzy-search-Active {
  border: 1px solid #2FA0C7;
  background-color: #091E25;
}

.searchSelect {
  min-width: 150px !important;
}

.pillsContainer {
  padding-top: 10px;
  display: flex;
  justify-content: center;
}

input {
  border: 1px solid #ced5d8;
  border-radius: 3px;
  color: #66747a;
  padding-left: 16px;
  font-size: 16px;
  font-family: "Source Sans Pro";
  height: 30px;
}

input:focus-visible {
  outline: none;
}

.searchIcon {
  cursor: pointer;
  position: absolute;
  top: 20%
}


ul {
  list-style-type: none !important;
  margin: 0;
  padding: 0;
}

.fuzzySuggest {
  padding: 10px 0px 10px 20px;
  cursor: pointer;
  color: #01151D;
}

.fuzzySuggest:hover {
  background: #f5f5f5;
}

.fuzzySuggestNoResult {
  padding: 10px 0px 10px 20px;
  pointer-events: none !important;
}

.recentLabel {
  padding: 20px 0px 20px 0px;
  margin: 0px 20px;
  font-size: 17px;
  color: #01151D;
  border-bottom: 1px solid #DFE5E8;
}

.buttonsContainer {
  position: sticky;
  top: 0;
  background: #fff;
  padding: 18px 0px 18px 10px;
  display: flex;
  border-bottom: 1px solid #DFE5E8;
}

.fuzzySearchButtonClass {
  cursor: pointer;
  margin: 0px 10px;
  border-radius: 50px;
  color: #01151D;
  padding: 3px 10px;
  height: 30px;
  align-content: center;
  background: #1E697614;
}

.selectedButtonClass {
  background: rgba(12, 123, 161, 0.25);
}

.suggestionsFooter {
  color: #09779E;
  font-size: 16px;
  font-style: normal;
  background: #FFFFFF;
  line-height: 24px;
  padding: 0px 0 10px 20px;
  cursor: pointer;
}

.input-searchinput-grey-magnifying-glass {
  background-image: url('../../assets/Grey Magnifying Glass.svg');
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 15px;
  padding-left: 40px;
}