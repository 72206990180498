.MainHeader {
    padding: 12px 20px 12px 20px;
    display: inline-flex;
    justify-content: space-between;
    flex-direction: row;
    background-color: #EDF3F4;
    width: calc(100vw - 40px);
    height: 40px;
    top: 0 !important;
}

.jllLogo {
    width: 105px;
    height: 28px;
    padding-top: 5px;
}

.userSection {
    display: flex;
    margin-left: auto;
    z-index: 1000
}

.userCircle {
    width: 40px;
    height: 40px;
    border-radius: 56px;
    border: 1.5px;
    display: flex;
    background-color: #FFFFFF;
    align-items: center;
    justify-content: center;
    color: #667579;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0025em;
}

.arrowDown {
    margin-left: 5px;
}

.MainHeaderSolDropdown {
    width: 100px;
}

.LogoutTooltip {
    color: black;
    z-index: 20;
    display: flex;
    align-items: flex-start;
    padding: 22px 0px;
    border-radius: 3px;
    display: flex;
    align-items: flex-start;
    padding: 22px 0px;
    border-radius: 3px;
    background-color: var(--surface-base-default);
    box-shadow: rgba(1, 21, 28, 0.06) 0px 6px 40px 0px, rgba(1, 21, 28, 0.05) 0px 3px 4px 0px;
    width: 100px;
    height: 35px;
    top: 65px;
    right: 20px;
    padding: 4px;
    border-radius: 8px;
    gap: 10px;
    position: absolute;
    justify-content: center;
    align-items: center;
}

.LogoutTooltipArrow {
    overflow: hidden;
    position: absolute;
    background-color: white;
    overflow: hidden;
    position: absolute;
    width: 1em;
    height: 0.71em;
    box-sizing: border-box;
    color: rgba(97, 97, 97, 0.9);
    top: 0px;
    margin-top: -0.71em;
    position: absolute;
    right: 10px;
    transform: translate3d(12.6667px, 0px, 0px);
    transform: rotate(45deg);
    transform-origin: 0px 100%;
}

.sol-icon-logout {
    color: #0C7BA1;
    font-size: 24px;
}

.noJllLogo {
    gap: 8px;
    display: flex;
    align-items: center;
}