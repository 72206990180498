.SearchHeader {
    background-color: #EDF3F4;
    padding: 0px 20px;
    padding-top: 64px;
}

.SearchHeaderText {
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -0.0175em;
    text-align: left;
}

.SearchHeaderButton {
    width: 169px;
    height: 20px;
    padding: 12px 16px 12px 16px;
    border-radius: 8px;
    gap: 8px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0025em;
    text-align: left;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #1E697614;
    color: #01151C;
}

.SearchHeaderButtons {
    display: flex;
    gap: 20px;
    margin: 20px 0px;
}

.SearchHeaderMobileSearchContainer {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
}

.SearchHeaderMobileSearch {
    border: 1px solid #E5EBED;
    height: 30px;
    padding: 10px 70px 11px 16px;
    border: 1px;
    border-radius: 100px;
    gap: 8px;
    font-size: 20px;
}

.SearchHeaderInput {
    display: flex;
    align-items: center;
}