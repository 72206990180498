.blogEntryHeaderContainer {
    /*display: flex;*/
    /*justify-content: space-between;*/

}

.blogEntryOutline {
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 350px;
    margin: 10px auto 0 auto;
}

.blogEntryContainer {
    display: flex;
}

.checkBoxLabel {
    margin-left: 20px;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.0025em;
    text-align: left;
    color: #667579;
}

.subMarketInput {
    margin-left: 10px;
    width: 100%;
    height: 34px;
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    padding: 0 1rem;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.0025em;
    text-align: left;
    color: #01151C;
}

.blogCheckboxPanel {
    display: flex;
    width: 150px;
    margin: 40px 0 0 60px;
    justify-content: space-between;
}

.echelonBlogTextArea {
    margin-top: 10px;
    border: 1px solid #CED6D8;
    width: 97%;
    border-radius: 6px;
    background: #FFFFFF;
    color: #667579 !important;
    font-family: Source Sans Pro;
    font-size: 16px !important;
    resize: none;
    padding-left: 8px;
}

.echelonBlogTextArea:focus {
    outline: none;
}

.blogEntryFooter {
    text-align: right;
    margin-top: 10px;
    display: flex;
    justify-content: right;
}

.footerButton {
    margin-right: 10px;
}

.addNewBlog {
    width: 80px;
    height: 20px;
    padding: 12px 16px 12px 16px;
    border-radius: 10px;
    gap: 8px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0025em;
    text-align: left;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #01151C;
    color: #FFFFFF;
}

.blogEntryHeader {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.addNewBlogIcon {
    width: 30px;
    height: 30px;
    left: 0;
    right: 0;
    bottom: 141px;
    cursor: pointer;
}

.BlogReportIcon {
    width: 30px;
    height: 30px;
    left: 0;
    right: 0;
    bottom: 141px;
}

.tooltip-container {
    position: relative;
    display: inline-block;
    padding-right: 20px;
  }

.tooltip-container-disabled {
    position: relative;
    display: inline-block;
    padding-right: 20px;
  }
  
  .tooltip-content {
    /* Add your content styles here */
  }
  
  .tooltip-popup {
    position: fixed;
    transform: translate(-55%, 20%);
    background-color: #000;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    white-space: nowrap;
    display: inline-block;
  }
  
  .tooltip-popup::after {
    content: "";
    position: relative;
    top: -8px;
    right: 50%;
    transform: translateX(-50%);
    border-top: 8px solid #000;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
  }

.addNewBlogIcon:hover {
    opacity: 1;
}

.ReactCollapse--collapse {
    transition: height 500ms;
}

.addNewText {
    margin-left: 5px;
    line-height: 1.8;
    font-weight: 600;
    cursor: pointer;
}

.exportText {
  margin-left: 5px;
  line-height: 1.8;
  font-weight: 600;
  color: white;
}

.pageLeftPanel {
    width: 20vw;
    height: 90vh;
    margin-top: 20px;
    border: 1px solid #DDDDDD;
    border-radius: 20px;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
    padding: 0 10px;
}

.sol-dropdown::part(list-container) {
    top: auto !important;
    left: auto !important;
}

.dropdown-heading-value {
    width: 200px !important;
    color: #667579;
}

.dropdown-content {
    z-index: 1000 !important;
    display: block !important;
    visibility: visible !important;
}

.item-renderer {
    align-items: center !important;
    color: #667579;
}

.dropdown-heading {
    height: 35px !important;
}

.dropdown-container {
    margin-bottom: 10px;
    font-size: 16px;
}

